









































































































































































































































































































































































































































































.main_questions_wrapper_container {
  .add_attributes_dialog_wrapper {
    .el-dialog {
      @media (min-width: 320px) and (max-width: 767px) {
        width: 90% !important;
      }
    }
  }
}
.show_statement_details {
  .el-dialog__close {
    display: block !important;
    cursor: pointer;
  }
}
